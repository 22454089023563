$(()->
  $('.tabs a').click((e) ->
    e.preventDefault()
    $(this).tab('show')
  )
  $('body:not(script)').contents().filter(()->
    this.nodeType == 3;
  ).replaceWith(()->
    this.nodeValue.replace(/[™®©]/g, '<sup>$&</sup>')
  )
  $('[data-toggle="tooltip"]').tooltip()
  header = $('header');
  origOffsetY = header.offset().top;
  height = $('body').height()
  scroll = ()->
    if $('body').width() > 992
      if ($(window).scrollTop() >= (origOffsetY + 30))
        $('body').addClass('scrolled')
      else
        $('body').removeClass('scrolled')
    else
      $('body').removeClass('scrolled')

  if (admin?)
    scroll = ()->
      false

  $('.checkbox-mod-no  .parent-check').click ->
    id = $(this).prop('for')
    if $(this).hasClass('checked')
        $(this).removeClass('checked')
#        $('#'+id).prop('checked', false);
      else
#        $('#'+id).prop('checked', true);
        $(this).addClass('checked')
##    else if $(this).is(':not(:checked)')
##        $(this).closest('.parent-check').addClass('checked')
  document.onscroll = scroll;
  $('.neos-contentcollection table').addClass('table')
  mactest = navigator.userAgent.indexOf("Mac") != -1
#  if (mactest)
#    $('head link[rel="stylesheet"]').last().after('<link rel="stylesheet" href="/_Resources/Static/Packages/App.Emtor/Styles/MacOsX.css" media="all">')
)

angular.module('EmtorFilters', []).filter('rangeFilter', ()->
  (input, data)->
    result = []
    if (data.min == false and data.max == false)
      result = input
    else
      angular.forEach(input, (item, key)->
        if (data.min <= parseInt(item[data.column]) and data.max >= parseInt(item[data.column]))
          result.push(item)
      )
    result
).filter('arrayFilter', ()->
  (input, data) ->
    result = []
    if (data.collection.length == 0)
      result = input
    else
      used = [] #key collection
      angular.forEach(input, (item, key) ->
        angular.forEach(data.collection, (citem, ckey) ->
          if (item[data.column] == citem and used.indexOf(key) < 0)
            result.push(item)
            used.push(key)
        )
      )
    result
).filter('deepArrayFilter', ()->
  (input, data) ->
    result = []
    if (data.collection.length == 0)
      result = input
    else
      used = [] #key collection
      angular.forEach(input, (item, key) ->
        angular.forEach(data.collection, (citem, ckey) ->
          angular.forEach(item[data.column], (value, tkey)->
            if (value == citem and used.indexOf(key) < 0)
              result.push(item)
              used.push(key)
          )
        )
      )
    result
)
app = angular.module('emtorApp',
  ['uiGmapgoogle-maps', 'ngStorage', 'checklist-model', 'EmtorFilters', 'ui-rangeSlider', 'pascalprecht.translate',
    'angularUtils.directives.dirPagination']).config(($interpolateProvider) ->
  $interpolateProvider.startSymbol('[[')
  $interpolateProvider.endSymbol(']]')
)
app.config(['$translateProvider', ($translateProvider)->
  $translateProvider.translations('pl', {
    'lpg': 'LPG',
    'diesel': 'Diesel',
    'electric': 'Elektryczny',
    "levels": "Tylko transport poziomy",
    "lifting": "Podnoszenie",
    "order": "Kompletacja zamówień",
    "towing": "Holowanie"
  })
  $translateProvider.translations('en_US', {
    'lpg': 'LPG',
    'diesel': 'Diesel',
    'electric': 'Electric',
    'Wózki spalinowe': 'Internal Combustion Trucks',
    'Wózki elektryczne': 'Electric Trucks',
    'Wózki podnośnikowe': 'Pallet Stackers',
    'Wózki paletowe': 'Pallet Trucks',
    'Serwisową': 'Service',
    'Wynajmu': 'Rental',
    'Outsorcingu': 'Outsource',
    'Części zamiennych': 'Spare parts',
    'Nowy': 'New',
    'Używany': 'Used'
  })

  $translateProvider.preferredLanguage('pl');
])
app.controller('MapCtrl', ($scope, $http)->
  $scope.goTo = (url) ->
    window.location = url
)

app.controller('UsedProductListCtrl', ($scope, $localStorage) ->
  $scope.filters = []
  $scope.isChecked = (collection, value) ->
    isChecked = false
    angular.forEach(collection, (item) ->
      if item == value
        isChecked = true
    )
    isChecked

  $scope.productList = products

  defaultFilters =
    year:
      column: "year"
      min: 1996
      max: 2018
    weight: []
    power:
      collection: []
      column: 'power'
    category:
      collection: []
      column: 'category'
    weight:
      column: 'weight'
      min: 1000
      max: 16000

  $scope.filter = defaultFilters
  $scope.clean = ()->
    $scope.filter = defaultFilters
  $scope.initSearch = (stat) ->
    $scope.searchIsOpen = stat
    if stat == false
      $scope.clean()
  $scope.filters.category = ["Wózki spalinowe", "Wózki elektryczne", "Wózki podnośnikowe", "Wózki paletowe"]
#  else
#    $scope.filters =
#      years:
#        min: 1996
#        max: 2018
#      power: ['lpg', 'diesel', 'electric']
#      weight:
#        min: 1000
#        max: 16000
#      category: ["Wózki spalinowe", "Wózki elektryczne", "Wózki podnośnikowe", "Wózki paletowe"]
)

app.controller('ProductListCtrl', ($scope, $localStorage) ->
  $scope.productList = products
  $scope.isChecked = (collection, value) ->
    isChecked = false
    angular.forEach(collection, (item) ->
      if item == value
        isChecked = true
    )
    isChecked
  $scope.filter =
    height:
      column: 'height'
      min: 0
      max: 17000
    power:
      collection: []
      column: 'power'
    category:
      collection: []
      column: 'category'
    weight:
      column: 'weight'
      min: 1000
      max: 16000

  $scope.filters =
    weight:
      min: 1000
      max: 16000
    power: ['lpg', 'diesel', 'electric']
    height:
      min: 0
      max: 17000
    category: ["levels", "lifting", "order", "towing"]
)
app.controller('IndexCtrl', ($scope, $http, $localStorage, $translate)->
  $scope.blur = false
  $scope.focus = false
  $scope.cookie = $localStorage.cookie
  $scope.setCookie = (info)->
    $scope.cookie = info
    $localStorage.cookie = info
  $scope.setLocale = (locale)->
    $translate.use(locale)
)
app.controller('SimpleInquiryCtrl', ($scope, $http, $localStorage)->
  $scope.send = false
  $scope.sendInquiry = ()->
    user = $scope.user
    $http.post('/sendSimple.json', user).success((data)->
      $scope.send = true
    ).error((data)->
      $scope.error = true
    )
  $scope.isChecked = (collection, value) ->
    isChecked = false
    angular.forEach(collection, (item) ->
      if item == value
        isChecked = true
    )
    isChecked
  $scope.power = ['lpg', 'diesel', 'electric']
  $scope.category = ["Nowy", "Używany"]
  $scope.service = ["Serwisową", "Wynajmu", "Outscorcingu", "Części zamiennych"]
)
app.controller('RentalSearchCtrl', ($scope, $http, $localStorage)->
  $scope.filters = []
  $scope.isChecked = (collection, value) ->
    isChecked = false
    angular.forEach(collection, (item) ->
      if item == value
        isChecked = true
    )
    isChecked
  $scope.setProducts = (data)->
    $scope.productList = data
  defaultFilters =
    years:
      column: 'year'
      min: 1996
      max: 2018
    heightRaising:
      column: 'heightRaising'
      min: 0
      max: 0
    category:
      collection: []
      column: 'category'
    weight:
      column: 'weight'
      min: 100
      max: 16000
    price:
      column: 'price'
      min: 100
      max: 16000
    power: ['lpg', 'diesel', 'electric']

  $scope.filter =
    year:
      column: 'year'
      min: 1996
      max: 2018
    height:
      column: 'height'
      min: 0
      max: 17000
    weight:
      column: 'weight'
      min: 0
      max: 17000
    power:
      collection: []
      column: 'power'
    category:
      collection: []
      column: 'category'
    weight:
      column: 'weight'
      min: 100
      max: 16000
#  $scope.filter = {}
  $scope.filters = angular.copy(defaultFilters)
  $scope.clean = ()->
    $scope.filter = defaultFilters
  $scope.initSearch = (stat) ->
    $scope.searchIsOpen = stat
    if stat == false
      $scope.clean()
  $scope.filters.category = ["Spalinowe", "Elektryczne", "Podnośnikowe", "Paletowe", "Nożycowe"]

)
app.controller('RentalCtrl', ($scope, $http, $localStorage)->
  $scope.user = {}
  $scope.setSubject = (subject)->
    $scope.user.subject = subject
  $scope.send = false
  $scope.sendInquiry = (kind)->
    user = $scope.user
    user.kind = kind
    $http.post('/sendSimple.json', user).success((data)->
      $scope.send = true
    ).error((data)->
      $scope.error = true
    )
  $scope.isChecked = (collection, value) ->
    isChecked = false
    angular.forEach(collection, (item) ->
      if item == value
        isChecked = true
    )
    isChecked
)
app.controller('ProductCtrl', ($scope, $http, $localStorage, $window)->
  $scope.send = false
  $scope.sendInquiry = (kind)->
    user = $scope.user
    inquiry = $scope.inquiryContainer
    data = {inquiry: inquiry, user: user, kind: kind}
    $http.post('/send.json', data).success((data)->
      $scope.send = true
      $scope.inquiryContainer = []
      $localStorage.inquiry = []
    ).error((data)->
      console.log data
    )


  $scope.downloadAll = (kind)->
    urls = []
    angular.forEach($scope.inquiryContainer, (element, key)->
#      window.open('/generate/' + btoa(encodeURIComponent(element.url)) + '.html', '_blank')
      if element.kind == kind
        urls.push(element.url)
    )

    $window.open('/generate-collection/' + btoa(encodeURIComponent(JSON.stringify(urls))) + '.html', '_blank')
  $scope.getAsPdf = (url)->
    $window.open('/generate/' + btoa(encodeURIComponent(url)) + '.html', '_blank')
  if $localStorage.inquiry
    $scope.inquiryContainer = $localStorage.inquiry
  else
    $scope.inquiryContainer = []
  $localStorage.inquiry = $scope.inquiryContainer
  $scope.AddToInquiry = (data)->
    if optionExists(data) == false && data.label
      $scope.inquiryContainer.push(data)
  $scope.removeFromInquiry = (data)->
    if optionExists(data)
      $scope.inquiryContainer.splice($.inArray(optionExists(data), $scope.inquiryContainer), 1)
  $scope.isInInquiry = (option)->
    optionExists(option)
  optionExists = (option)->
    toReturn = false
    angular.forEach($scope.inquiryContainer, (item) ->
      if option.label == item.label
        toReturn = item
      else
        false
    )
    toReturn
)
app.controller('SparePartsCtrl', ($scope, $http, $localStorage, $window)->
  $scope.spareParts = products
  $scope.filters = {
    class01: []
    class02: []
    class03: []
    class04: []
    class05: []
    class06: []
    class07: []
    class08: []
    class09: []
    class10: []
  }
  angular.forEach($scope.spareParts, (item) ->
    angular.forEach($scope.filters, (items, keys) ->
      if (item[keys] && items.indexOf(item[keys]) < 0)
        items.push(item[keys])
    )
  )
  $scope.send = false
  $scope.sendInquiry = (kind)->
    user = $scope.user
    inquiry = $scope.inquiryContainer
    data = {inquiry: inquiry, user: user, kind: kind}
    $http.post('/send.json', data).success((data)->
      $scope.send = true
      $scope.inquiryContainer = []
      $localStorage.inquiry = []
    ).error((data)->
      console.log data
    )

  if $localStorage.inquiry
    $scope.inquiryContainer = $localStorage.inquiry
  else
    $scope.inquiryContainer = []
  $localStorage.inquiry = $scope.inquiryContainer
  $scope.AddToInquiry = (data)->
    if optionExists(data) == false && data.label
      $scope.inquiryContainer.push(data)
  $scope.removeFromInquiry = (data)->
    if optionExists(data)
      $scope.inquiryContainer.splice($.inArray(optionExists(data), $scope.inquiryContainer), 1)
  $scope.isInInquiry = (option)->
    optionExists(option)
  optionExists = (option)->
    toReturn = false
    angular.forEach($scope.inquiryContainer, (item) ->
      if option.label == item.label
        toReturn = item
      else
        false
    )
    toReturn

  $scope.downloadAll = (kind)->
    urls = []
    angular.forEach($scope.inquiryContainer, (element, key)->
#      $window.open('/generate/' + btoa(encodeURIComponent(element.url)) + '.html', '_blank')
      if element.kind == kind
        urls.push(element.url)
    )
    $window.open('/generate-collection/' + btoa(encodeURIComponent(JSON.stringify(urls))) + '.html', '_blank')
  $scope.getAsPdf = (url)->
    $window.open('/generate/' + btoa(encodeURIComponent(url)) + '.html', '_blank')
)
